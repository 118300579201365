<template>
  <div>
    <b-container fluid>
      <small>请选择查询组织</small><br>
      <el-select class="mt-3 w-50" v-model="selected" placeholder="请选择" @change="getLogsByOrganizationId">
        <el-option
            v-for="item in myOrganizations"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <b-row>
        <b-col lg="12" class="my-1">
          <b-form-group
              label="按条件查找"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0 w-50"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="请输入..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
              label="每页展示条数"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-3">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-table
          :items="logs"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          show-empty
          small
      >
        <template #cell(success)="row">
          <el-tag v-if="row.item.success" type="success">成功</el-tag>
          <el-tag v-else type="danger">失败</el-tag>
        </template>

        <template #cell(action)="row">
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? '收起' : '展开' }}详情
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <ul v-for="(value, key) in row.item" :key="key">
              <li v-if="getLabel(key)!==''">{{
                  getLabel(key)
                }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import {showMyOrganizations, showOperationLogsByOrganizationId} from "@/api";

export default {
  name: "OrganizationLogs",
  data() {
    return {
      selected: '',
      myOrganizations: [],
      logs: [],
      totalRows: 200,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 200, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      fields: [
        {key: 'id', label: '编号', sortable: true},
        {key: 'success', label: '结果', sortable: true},
        {key: 'operatorName', label: '操作者', sortable: true},
        {key: 'operateTime', label: '时间', sortable: true},
        {key: 'operation', label: '操作'},
        {key: 'action', label: '操作'}
      ],
      labels: [
        {key: 'id', label: '编号'}, {key: 'operatorId', label: '操作者编号'}, {key: 'operatorName', label: '操作者'},
        {key: 'organizationName', label: '所在组织'}, {key: 'operation', label: '执行操作'},
        {key: 'parameter', label: '参数'}, {key: 'result', label: '结果'}, {key: 'operateTime', label: '时间'}
      ]
    }
  },
  mounted() {
    this.getMyOrganizations()
  },
  methods: {
    getMyOrganizations() {
      showMyOrganizations(1, 50).then(res => {
        if (res.data.status) {
          this.myOrganizations = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getLogsByOrganizationId() {
      showOperationLogsByOrganizationId(this.selected, 1, 200).then(res => {
        if (res.data.status) {
          this.logs = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getLabel(key) {
      let flag = ''
      this.labels.forEach(l => {
        if (key === l.key) {
          flag = l.label
        }
      })
      return flag
    }
  }
}
</script>

<style scoped>

</style>
