<template>
<div>
  <b-container fluid class="p-3">
    <b-tabs content-class="mt-3">
      <b-tab title="我的日志" active>
      <MyLogs></MyLogs>
      </b-tab>
      <b-tab title="组织日志">
      <OrganizationLogs></OrganizationLogs>
      </b-tab>
      <b-tab title="全部日志"><AllOrganizationLogs></AllOrganizationLogs></b-tab>
    </b-tabs>
  </b-container>
</div>
</template>

<script>
import MyLogs from "@/components/Logs/MyLogs";
import OrganizationLogs from "@/components/Logs/OrganizationLogs";
import AllOrganizationLogs from "@/components/Logs/AllOrganizationLogs";
export default {
  name: "Journal",
  components: {AllOrganizationLogs, OrganizationLogs, MyLogs}
}
</script>

<style scoped>

</style>
